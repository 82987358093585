import React from "react";
import "./index.scss";
import moment from "moment";
import {
  Layout,
  Row,
  Col,
  Form,
  Avatar,
  Button,
  Input,
  Calendar,
  Icon,
  Select,
  Typography,
  Card,
  Skeleton,
  Tooltip,
  Modal,
} from "antd";

import SubmitModal from "./components/SubmitModal";
import Logo from "../shared/Logo";
import Footer from "../shared/AdminFooter";

const Option = Select.Option;

const dateToday = moment(new Date()).format("YYYY-MM-DD");
const currentMonth = moment(new Date()).format("YYYY-MM");

class Scheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slotsData: [],
      dayOffsData: [],
      selectedTime: "",
      selectedDate: dateToday,
      selectedMonth: currentMonth,
      selectedMode: null,
      visible: false,
      doctorData: null,
      scheduleDays: [],
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { selectedTime, selectedDate } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = values;
        params.appointment_time = selectedTime;
        params.appointment_date = selectedDate;
        params.doctor = this.state.doctorData.id;
        params.status = 0;
        params.client_id = process.env.REACT_APP_CLIENT_ID;

        if (params.mode === "Other") {
          params.mode = params.other_mode;
          delete params.other_mode;
        }

        if (!selectedTime.length) {
          Modal.warning({
            title: "Appointment Time is Required.",
            content: "",
          });

          return false;
        }

        await this.props
          .onSubmitScheduleAppointment(params)
          .then((response) => {
            if (response.error) {
              Modal.success({
                title: "Unable to book appointment.",
                content: "",
              });
            } else {
              this.showModal();
              this.props.form.resetFields();
            }
          });
      }
    });
  };

  changeMode = (value) => {
    this.setState({ selectedMode: value });
  };

  selectTime = (time) => {
    this.setState({
      selectedTime: time,
    });
  };

  disablingDate = (current) => {
    const { dayOffsData } = this.state;

    const calendarDate = moment(current).format("YYYY-MM-DD");
    const calendarDay = moment(current).format("dddd");

    const exist = dayOffsData.filter((day) => day.date === calendarDate);

    if (current <= moment().subtract(1, "days") || exist.length) {
      return true;
    }

    if (this.state.scheduleDays.indexOf(calendarDay) < 0) {
      return true;
    }

    return false;
  };

  selectDate = (value) => {
    const dateSelected = moment(value).format("YYYY-MM-DD");
    const monthSelected = moment(value).format("YYYY-MM");

    this.setState({
      selectedDate: dateSelected,
      selectedMonth: monthSelected,
    });
  };

  showModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  replaceTime = (data) => {
    let newData = data.replace("12:", ":");
    newData = newData.replace(":", "");
    newData = newData.replace(" ", "");
    newData = newData.replace(/am/gi, "*1");
    newData = newData.replace(/pm/gi, "*1000");

    return parseInt(newData);
  };

  componentDidMount() {
    // this.props.onLoadSlotsData(currentMonth);
    // this.props.onLoadDayOffsData();

    const queryString = new URLSearchParams(this.props.history.location.search);
    const doctorId = queryString.get("id");
    this.props.onLoadDoctor({ id: doctorId });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.doctorData !== nextProps.doctorData) {
      const doctorData = nextProps.doctorData;
      let days = [];
      doctorData.schedule.forEach((row) => {
        days.push(row.day);
      });

      let scheduleDays = [...new Set(days)];

      this.setState({
        doctorData: doctorData,
        scheduleDays: scheduleDays,
      });
    }

    // if (this.props.slotsData !== nextProps.slotsData) {
    //   this.setState({ slotsData: nextProps.slotsData });
    // }

    // if (this.props.dayOffsData !== nextProps.dayOffsData) {
    //   this.setState({ dayOffsData: nextProps.dayOffsData });
    // }
  }

  handleTimeSelection = (time) => {
    this.setState({
      selectedTime: time,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      selectedTime,
      selectedDate,
      slotsData,
      selectedMode,
      scheduleDays,
      doctorData,
    } = this.state;
    const { isLoading, isProcessing } = this.props;

    const slots =
      doctorData && doctorData.schedule
        ? doctorData.schedule.sort((a, b) => {
            const x = this.replaceTime(a.time);
            const y = this.replaceTime(b.time);
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          })
        : [];

    return (
      <React.Fragment>
        <Layout className="schedule-layout fade-in">
          <Layout.Content className="schedule-content">
            <Row type="flex" align="middle" gutter={10}>
              <Col xs={7} sm={24} md={9} align="center">
                <Logo {...this.props} />
              </Col>
              <Col xs={17} sm={24} md={15}>
                <Typography.Title level={2} className="page-title">
                  YOUR FERTILITY LINKS SCHEDULER
                </Typography.Title>
                <Typography.Paragraph className="page-description">
                  Search by doctor's name by specialty <br /> and book
                  appointments based on their schedule!
                </Typography.Paragraph>
              </Col>
            </Row>
            <Card className="scheduler-card">
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="assigned-section"
              >
                <Col xs={24} sm={24} md={14} className="calendar-column">
                  <Typography.Paragraph className="booking-with-text">
                    You are booking appointment with:
                  </Typography.Paragraph>
                  <Card className="appointment-with-card">
                    <Skeleton loading={isLoading} avatar active>
                      <Card.Meta
                        avatar={
                          // <object
                          //   data="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                          //   type="image/png"
                          //   class="ant-avatar ant-avatar-circle"
                          //   style={{ height: "50px", width: "50px" }}
                          // >
                          <Avatar
                            src={`${
                              doctorData &&
                              doctorData.detail &&
                              doctorData.detail &&
                              doctorData.detail.image !== null
                                ? process.env.REACT_APP_S3_BUCKET +
                                  doctorData.detail.image
                                : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                            }`}
                            size={70}
                          />
                          // </object>
                        }
                        title={`${doctorData ? doctorData.firstname : ""} ${
                          doctorData ? doctorData.lastname : ""
                        }`}
                        description={
                          <React.Fragment>
                            <Typography.Paragraph>
                              {doctorData
                                ? doctorData.detail.specialization
                                : ""}
                            </Typography.Paragraph>

                            <Typography.Paragraph>
                              {doctorData ? doctorData.detail.hospital : ""}
                            </Typography.Paragraph>

                            <Typography.Paragraph>
                              {doctorData ? doctorData.detail.location : ""}
                            </Typography.Paragraph>
                          </React.Fragment>
                        }
                      />
                    </Skeleton>
                  </Card>
                  <Card className="appointment-with-card-schedule">
                    <Card.Meta
                      title="Fertility Links Scheduler"
                      description={
                        <React.Fragment>
                          <Typography.Paragraph className="date">
                            {scheduleDays ? scheduleDays.join(", ") : ""}
                            {/* <br />
                            9:00 A.M - 6:00 P.M. */}
                          </Typography.Paragraph>

                          <Typography.Paragraph>
                            Select Time and Date
                          </Typography.Paragraph>
                        </React.Fragment>
                      }
                    />
                  </Card>

                  <Skeleton loading={isLoading} active>
                    <Calendar
                      fullscreen={false}
                      disabledDate={this.disablingDate}
                      headerRender={({
                        value,
                        type,
                        onChange,
                        onTypeChange,
                      }) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];

                        const current = value.clone();

                        const localeData = value.localeData();
                        const months = [];

                        for (let i = 0; i < 12; i++) {
                          current.month(i);
                          months.push(localeData.monthsShort(current));
                        }

                        for (let index = start; index < end; index++) {
                          monthOptions.push(months[index]);
                        }

                        const month = value.month();
                        const year = value.year();

                        const handleNextMonth = () => {
                          const newValue = value.clone();
                          newValue.month(parseInt(month + 1, 10));
                          onChange(newValue);

                          this.props.onLoadSlotsData(year + "-" + month);
                        };

                        const handlePrevMonth = () => {
                          const newValue = value.clone();
                          newValue.month(parseInt(month - 1, 10));
                          onChange(newValue);

                          this.props.onLoadSlotsData(year + "-" + month);
                        };

                        return (
                          <Row
                            type="flex"
                            justify="space-between"
                            className="calendar-header"
                          >
                            <Col span={12}>
                              {moment(value).format("MMMM ") +
                                moment(value).format("YYYY")}
                            </Col>
                            <Col span={12} align="right">
                              <Icon
                                type="left"
                                onClick={handlePrevMonth}
                                className="date-control"
                              />
                              <Icon
                                type="right"
                                onClick={handleNextMonth}
                                className="date-control"
                              />
                            </Col>
                          </Row>
                        );
                      }}
                      onSelect={this.selectDate}
                    />
                  </Skeleton>

                  <Typography.Paragraph className="selected-date">
                    {moment(selectedDate).format("dddd, MMMM DD, YYYY")}
                  </Typography.Paragraph>

                  <Typography.Paragraph>
                    <div className="form-label">
                      {moment(selectedDate).format("dddd, MMMM DD, YYYY")}
                    </div>
                    <div className="appointment-times">
                      {slots.map((row, index) => {
                        if (row.day !== moment(selectedDate).format("dddd")) {
                          return "";
                        }

                        // appointment_date
                        let disabled = false;

                        // const appointments = row.appointment.filter((app) => {
                        //   if (
                        //     app.appointment_date ===
                        //     moment(selectedDate)
                        //       .format("YYYY-MM-DD")
                        //       .toString()
                        //   ) {
                        //     return true;
                        //   }

                        //   return false;
                        // });

                        // appointments.sort((a, b) => {
                        //   const x = moment(a.created_at)
                        //     .format("X")
                        //     .toString();
                        //   const y = moment(b.created_at)
                        //     .format("X")
                        //     .toString();
                        //   if (x < y) {
                        //     return -1;
                        //   }
                        //   if (x > y) {
                        //     return 1;
                        //   }
                        //   return 0;
                        // });

                        // if (appointments.length >= row.slots) {
                        //   disabled = true;
                        // }

                        let className =
                          selectedTime === row.time
                            ? "time-button time-selected"
                            : "time-button";

                        className = disabled
                          ? className + " disabled"
                          : className;

                        if (disabled) {
                          return (
                            <Tooltip
                              placement="bottom"
                              color="red"
                              title="Unable to select due to no slot available"
                            >
                              <Button
                                className={className}
                                onClick={() =>
                                  this.handleTimeSelection(row.time)
                                }
                                key={index}
                                disabled={true}
                              >
                                {row.time}
                              </Button>
                            </Tooltip>
                          );
                        }

                        return (
                          <Button
                            className={className}
                            onClick={() => this.handleTimeSelection(row.time)}
                            key={index}
                          >
                            {row.time}
                          </Button>
                        );
                      })}
                    </div>
                  </Typography.Paragraph>
                </Col>

                <Col xs={24} sm={24} md={10} className="form-column">
                  <Form onSubmit={this.onSubmit} colon={false}>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item label="First Name">
                        {getFieldDecorator("firstname", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                          ],
                        })(<Input placeholder="First Name" />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item label="Last Name">
                        {getFieldDecorator("lastname", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                          ],
                        })(<Input placeholder="Last Name" />)}
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item label="Contact Number">
                        {getFieldDecorator("mobile", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your mobile number!",
                            },
                          ],
                        })(<Input placeholder="Mobile Number" />)}
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item label="Email Address">
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              message: "Please input a valid email!",
                            },
                            {
                              type: "email",
                              message: "Invalid email format",
                            },
                          ],
                        })(<Input placeholder="Email" />)}
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label="Preferred Mode of Consultation">
                        {getFieldDecorator("mode", {
                          rules: [
                            {
                              required: true,
                              message:
                                "Please input your mode of consultation!",
                            },
                          ],
                        })(
                          <Select
                            onChange={this.changeMode}
                            placeholder="Select One"
                            style={{ width: "100%" }}
                          >
                            <Option value="Google Meets">Google Meets</Option>
                            <Option value="Phone Call">Phone Call</Option>
                            <Option value="Viber">Viber</Option>
                            <Option value="Zoom">Zoom</Option>
                            <Option value="Others">Others</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <Form.Item label="Referring OB-GYN">
                        {getFieldDecorator("refer_by", {
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: "Please input your refer by!",
                          //   },
                          // ],
                        })(<Input placeholder="Referring OB-GYN" />)}
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
                      <Form.Item>
                        <Button
                          className="btn-primary confirm-appointment-btn"
                          htmlType="submit"
                          loading={isProcessing}
                          disabled={isProcessing}
                        >
                          Confirm Appointment
                        </Button>
                      </Form.Item>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Layout.Content>
          <SubmitModal visible={visible} showModal={this.showModal} />
        </Layout>
        <Footer isSearch={true} other={true} />
      </React.Fragment>
    );
  }
}

const SchedulerForm = Form.create({ name: "scheduler_form" })(Scheduler);

export default SchedulerForm;
