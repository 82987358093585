import React, { Component } from "react";
import { Upload, Icon, message, Button } from "antd";
import base64 from "../../../utils/base64";
import futch from "../../../utils/futch";
import { getToken } from "../../../utils/token";
import "./index.css";

class ImageUploader extends Component {
  state = {
    loading: false,
    fileList: [],
    uploading: false,
    imagePayload: null,
    previewImage: null,
    previewVisible: false,
    imageUrl: null,
    accept: "image/*",
  };

  static defaultProps = {
    isMove: true,
  };

  handleUploadRequest = async () => {
    const { imagePayload } = this.state;

    this.setState({
      uploading: true,
    });

    let progressFn = (p) => {
      const percentage = Math.floor((p.loaded / p.total) * 100);
      console.log(percentage);
      // this.setState({ uploadPercentage: percentage });
    };

    let url = `${process.env.REACT_APP_API_URL}/admin/files/upload-image`;
    if (this.state.accept !== "image/*") {
      url = `${process.env.REACT_APP_API_URL}/admin/files/upload`;
    }

    if (imagePayload) {
      let response = await futch(
        url,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${getToken()}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(imagePayload),
        },
        progressFn
      );

      this.setState({
        uploading: false,
      });

      return JSON.parse(response);
    }
  };

  handleChange = async (info) => {
    const {
      match: { params },
      fileFolder,
      accept,
    } = this.props;

    let fileList = info.fileList;
    fileList = fileList.slice(-1);

    let file = info.file;

    const valid = this.beforeUpload(file);
    if (!valid) {
      return false;
    }

    const b64 = await base64(file);

    let medias = b64 ? b64.split(",")[1] : "";

    let imagePayload = {
      file: medias,
      keyPath: fileFolder,
    };

    if (this.state.accept !== "image/*") {
      imagePayload = {
        file: b64,
        keyPath: fileFolder,
      };
    }

    this.setState({
      fileList: [
        {
          ...file,
          ...imagePayload,
          file: file,
        },
      ],
      imagePayload,
      imageUrl: window.URL.createObjectURL(file),
    });
  };

  getImageUrl = () => {
    return this.state.imageUrl;
  };

  uploadingState = () => {
    return this.state.uploading;
  };

  clearImage = () => {
    this.setState({ fileList: [], imageUrl: null });
  };

  componentDidMount() {
    const { imageUrl, accept } = this.props;

    let acceptFile = "image/*";
    if (accept && accept !== "") {
      acceptFile = accept;
    }

    this.setState({
      imageUrl,
      accept: acceptFile,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.imageUrl !== prevProps.imageUrl && this.props.isMove) {
      this.setState({ imageUrl: this.props.imageUrl });
    }
  }

  beforeUpload = (file) => {
    const fileSizeOk = file.size / 1024 / 1000 < 20;
    if (!fileSizeOk) {
      message.error(
        "Image must smaller than 20mb. Your file is " +
          (file.size / 1024 / 1000).toFixed(2) +
          "mb"
      );
    }
    return fileSizeOk;
  };

  render() {
    const { imageUrl } = this.state;
    const {
      buttonDesc,
      noDesign,
      // imageUrl
    } = this.props;

    const uploadProps = {
      multiple: false,
      previewVisible: false,
      showUploadList: false,
      onChange: this.handleChange,
      onRemove: (file) => {
        this.setState(({ fileList }) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      // beforeUpload: this.beforeUpload,
      // beforeUpload: file => {
      //   const fileSizeOk = file.size / 1024 / 1000 < 8;
      //   if (!fileSizeOk) {
      //     message.error(
      //       "Image must smaller than 8mb. Your file is " +
      //         (file.size / 1024 / 1000).toFixed(2) +
      //         "mb"
      //     );
      //   }
      //   return fileSizeOk;
      // },
      beforeUpload: (file) => {
        return false;
      },
      accept: this.state.accept,
      fileList: this.state.fileList,
    };

    const uploadButton = (icon, text) => (
      <div className="upload-container">
        <Icon type={this.state.loading ? "loading" : icon} />
        <div className="ant-upload-text">{text}</div>
      </div>
    );

    if (noDesign) {
      return (
        <Upload {...uploadProps}>
          {imageUrl ? (
            <Button type="primary" disabled={this.props.disabled} block>
              Change
            </Button>
          ) : (
            <Button type="primary" disabled={this.props.disabled} block>
              Upload
            </Button>
          )}
        </Upload>
      );
    }

    if (this.state.accept !== "image/*") {
      return (
        <div>
          <div>
            <Upload
              {...uploadProps}
              listType="picture-card"
              className="avatar-uploader"
            >
              {imageUrl ? (
                <div style={{ backgroundColor: "#EEE" }}>
                  <div className="ant-upload-text">{imageUrl}</div>
                </div>
              ) : (
                uploadButton("home", buttonDesc)
              )}
            </Upload>
          </div>
        </div>
      );
    }

    return (
      <Upload
        {...uploadProps}
        listType="picture-card"
        className="avatar-uploader"
      >
        {imageUrl ? (
          <div style={{ backgroundColor: "#EEE" }}>
            <img
              src={
                imageUrl.match(/blob\:/g)
                  ? imageUrl
                  : process.env.REACT_APP_S3_BUCKET + imageUrl
              }
              alt="avatar"
            />

            <div className="ant-upload-text">{buttonDesc}</div>
          </div>
        ) : (
          uploadButton("home", buttonDesc)
        )}
      </Upload>
    );
  }
}

export default ImageUploader;
