import React from "react";
import "./index.scss";

import { Link } from "react-router-dom";
import { Layout, Row, Col, Typography, List, Icon } from "antd";

import "./index.scss";
import logo from "../../../assets/images/fl-scheduler-logo.png";

export default function Footer(props) {
  const paths = [
    {
      label: "Privacy",
      route: "/terms",
    },
    {
      label: "Terms",
      route: "/terms",
    },
    {
      label: "Contact",
      route: "/contact",
    },
  ];

  return (
    <Layout.Footer
      className={`admin-footer ${props.isSearch &&
        "admin-footer-padding"} ${props.other && "admin-footer-border-top"}`}
    >
      <Row type="flex" justify="space-between" align="middle">
        <Col lg={6} xs={24} align="center" className="w-logo">
          <img src={logo} alt="ipadama" className="logo" />
        </Col>
        <Col lg={11}>
          <Typography.Paragraph>
            This application is powered by ipadama 360
          </Typography.Paragraph>
        </Col>
        <Col lg={7} align="left">
          {/* <div className="icons-list">
            <Link to="/">
              <Icon type="facebook" />
            </Link>
            <Link to="/">
              <Icon type="twitter" />
            </Link>
            <Link to="/">
              <Icon type="instagram" />
            </Link>
          </div> */}
        </Col>
      </Row>
      {props.isSearch && (
        <List
          size="small"
          itemLayout="horizontal"
          dataSource={paths}
          renderItem={(item) => (
            <List.Item>
              <Link to={item.route}>{item.label}</Link>
            </List.Item>
          )}
        />
      )}
    </Layout.Footer>
  );
}
