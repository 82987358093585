import React, { useState, memo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu, Layout } from "antd";
import * as _ from "lodash";
import { getUser, getClients, getClient } from "../../../utils/token";
import "./index.scss";

import Logo from "../../../assets/images/fl-scheduler-logo.png";

function Header({ isClientLoading, location, paths }) {
  const [history, setHistory] = useState(useHistory());

  let selectedKeys = _.chain(paths)
    .filter((path) => location.pathname.includes(path.slug))
    .map("slug")
    .value();
  selectedKeys = !selectedKeys.length
    ? [_.find(paths, ["default", true]).slug]
    : selectedKeys;

  function onLogout() {
    localStorage.clear();
    history.push("/admin/login");
  }

  const user = JSON.parse(getUser());
  const clients = getClients();
  const selectedClientId = getClient();
  let selectedClient = clients
    ? clients.filter((row) => {
        if (row.id === selectedClientId) {
          return row;
        }

        return false;
      })
    : [];

  if (selectedClient.length > 0) {
    selectedClient = selectedClient[0];
  }

  return (
    <Layout.Header className="admin-header">
      <Link to="/admin" className="logo">
        <img src={Logo} alt="logo" />
        <h2>Scheduler</h2>
      </Link>

      <Menu mode="horizontal" className="ant-menu-ipadama right">
        <Menu.Item>{`${user.firstname} ${user.lastname}`}</Menu.Item>

        <Menu.Item>
          <Link to="/admin/profile">My Profile</Link>
        </Menu.Item>

        <Menu.Item onClick={onLogout}>Logout</Menu.Item>
      </Menu>
    </Layout.Header>
  );
}

export default memo(Header);
