import React, { Component } from "react";
import {
  Form,
  Layout,
  Card,
  Row,
  Col,
  Modal,
  Input,
  Button,
  Select,
} from "antd";
import "./index.scss";
import { getUser } from "../../utils/token";
import ImageUploader from "../shared/ImageUploader";

const { Content } = Layout;
const { Option } = Select;

class Profile extends Component {
  initialState = {
    selectedRow: null,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.fileUploader = React.createRef();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmitUpdateUser } = this.props;
    const selectedRow = JSON.parse(getUser());

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          ...values,
        };

        if (!selectedRow.is_admin) {
          let media = null;
          let imageUrl =
            selectedRow && selectedRow.detail && selectedRow.detail.image
              ? selectedRow.detail.image
              : "";
          media = await this.fileUploader.current.handleUploadRequest();
          params.image = media && media.url ? media.url : imageUrl;
        }

        params.is_admin = selectedRow.is_admin;
        params.id = selectedRow.id;
        await onSubmitUpdateUser(params).then((response) => {
          if (response.error) {
            Modal.success({
              title: "Unable to update profile.",
              content: "",
            });
          } else {
            Modal.success({
              title: "Profile has been updated successfully",
              content: "",
              onCancel: (e) => {
                window.location.href = "/admin/profile";
              },
            });
            // console.log("response", response.response.data);
            localStorage.setItem(
              "USER",
              JSON.stringify(response.response.data)
            );
          }
        });

        this.props.form.resetFields();
      }
    });
  };

  componentDidMount() {
    this.props.onLoadUsersData();

    this.setState({selectedRow: JSON.parse(getUser())})
  }

  componentWillReceiveProps(nextProps) {
    let selectedRow = null;
    if (nextProps.usersData) {
      nextProps.usersData.forEach((a) => {
        if (a && this.state.selectedRow && a.id === this.state.selectedRow.id) {
          selectedRow = a;
        }
      });
    }

    if (selectedRow !== null) {
      this.setState({selectedRow: selectedRow})
      localStorage.setItem("USER", JSON.stringify(selectedRow));
    }
  }

  createSchedule = (RawSchedule) => {
      let newSchedule = [];
      let ctrSchedule = [];
      const days = {
        "sunday": "Su",
        "monday": "M",
        "tuesday": "T",
        "wednesday": "W",
        "thursday": "Th",
        "friday": "F",
        "saturday": "Sa"
      };

      const daysSorted = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

      const sortDays = function (a, b) {
        a = daysSorted.indexOf(a);
        b = daysSorted.indexOf(b);
        return a < b ? 0 : 1;
      };

      RawSchedule.forEach(row => {
        const index = ctrSchedule.indexOf(row.time);
        if (index > -1) {
          newSchedule[index].day.push(days[row.day.toLowerCase()]);
        } else {
          const data = {
             "time": row.time,
             "duration": row.name,
             "day": [days[row.day.toLowerCase()]]
          };
          newSchedule.push(data);
          ctrSchedule.push(row.time);
        }
      });

      let compactSchedule = [];
      ctrSchedule = [];
      newSchedule.forEach(row => {
        const days = row.day.sort(sortDays).join();
        const index = ctrSchedule.indexOf(days);
        if (index > -1) {
          compactSchedule[index].time.push(row.time);
          compactSchedule[index].duration.push(row.duration);
        } else {
          const data = {
             "time": [row.time],
             "duration": [row.duration],
             "day": row.day
          };
          compactSchedule.push(data);
          ctrSchedule.push(days);
        }
      });

      return compactSchedule;
  }

  render() {
    const { selectedRow } = this.state;

    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;

    if (selectedRow == null) {
      return "";
    }

    let schedule =[];
    if (selectedRow.schedule) {
      schedule = this.createSchedule(selectedRow.schedule);
    }
    console.log("raw schedule:", selectedRow.schedule);
    console.log("schedule:", schedule);

    return (
      <Layout className="appointment-container">
        <Content className="appointment-header">My Profile</Content>
        <Content className="appointment-card">
          <Card>
            <Row type="flex" justify="center" gutter={30}>
              <Form onSubmit={this.handleSubmit} className="user-form">
                {selectedRow && selectedRow.detail ? (
                  <Col span={24} style={{ textAlign: "center" }}>
                    <ImageUploader
                      match={this.props.match}
                      ref={this.fileUploader}
                      fileFolder="doctors"
                      buttonDesc="Upload Image"
                      imageUrl={
                        selectedRow && selectedRow.detail && selectedRow.detail.image
                          ? selectedRow.detail.image
                          : ""
                      }
                    />
                  </Col>
                ) : ""}
                <Col span={12}>
                  <Form.Item label="First Name">
                    {getFieldDecorator("firstname", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter First Name!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.firstname : "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator("lastname", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Last Name!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.lastname : "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Username">
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Username!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.username : "",
                    })(<Input disabled={selectedRow ? true : false} />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={selectedRow ? "Password (Leave blank if not to change)" : "Password"}>
                    {getFieldDecorator("password", {
                      rules: [],
                      initialValue: selectedRow ? selectedRow.password : "",
                    })(<Input type="password" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Email">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Email!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.email : "",
                    })(<Input type="email" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mobile">
                    {getFieldDecorator("mobile", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Mobile!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.mobile : "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
                {selectedRow && !selectedRow.is_admin ? (
                  <Col span={12}>
                    <Form.Item label="Location">
                      {getFieldDecorator("location", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Location!",
                          },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.detail.location
                          : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                ) : null}
                {selectedRow && !selectedRow.is_admin ? (
                  <Col span={12}>
                    <Form.Item label="Specialization">
                      {getFieldDecorator("specialization", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Specialization!",
                          },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.detail.specialization
                          : "",
                      })(
                        <Select placeholder="Specialization">
                          <Option value="Reproductive Medicine Specialist">
                            Reproductive Medicine Specialist
                          </Option>
                          <Option value="OB-GYNE">OB-GYNE</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                ) : null}
                {selectedRow && !selectedRow.is_admin ? (
                  <Col span={12}>
                    <Form.Item label="Hospital">
                      {getFieldDecorator("hospital", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Hospital!",
                          },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.detail.hospital
                          : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                ) : null}
                {selectedRow && !selectedRow.is_admin && schedule ? (
                  <Col span={12}>
                    <Form.Item label="Schedules">
                      <div style={{ border: "1px solid #d9d9d9", padding: "8px 11px" }}>
                      {schedule.map((row, index) => 
                      {
                        return (
                        <div span={8} key={index} style={{ marginBottom: "20px", lineHeight: 1 }}>
                            <span style={{ fontWeight: "bold" }}>{row.day.join("")}</span><br />
                            <span style={{ marginRight: "30px" }}>{row.duration.join(", ")}</span>
                        </div>
                        );
                      })}
                      </div>
                    </Form.Item>
                  </Col>
                ) : null}
                <Col span={24} align="right">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Card>
        </Content>
      </Layout>
    );
  }
}

const ProfileForm = Form.create({ name: "appointment_form" })(Profile);

export default ProfileForm;
