import React from "react";
import { Modal, Typography, Button } from "antd";

export default function SubmitModal(props) {
  const { visible, showModal } = props;
  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      centered={true}
      width={600}
      className="booked-modal"
    >
      <Typography.Title level={2}>Appointment Booked!</Typography.Title>
      <Typography.Paragraph>
        You will receive an email and sms once your schedule has been confirmed.
      </Typography.Paragraph>

      <Button onClick={() => showModal()} className="btn-primary">
        Close
      </Button>
    </Modal>
  );
}
