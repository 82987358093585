import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Spin, Modal } from "antd";
import "./index.scss";
import Logo from "../../assets/images/fl-scheduler-logo.png";
import { getToken } from "../../utils/token";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgot: false,
    };
  }

  showForgotModal = (e) => {
    this.setState({ showForgot: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = values;

        if (values.password !== values.confirm) {
          Modal.success({
            title: "New password and confirm password is not the same.",
            content: "",
          });

          return false;
        }

        params.client_id = process.env.REACT_APP_CLIENT_ID;
        params.token = this.props.match.params.token;

        await this.props.onSubmitReset(params).then((response) => {
          if (response.error) {
            Modal.success({
              title: "Unable to process request.",
              content: "",
            });
          } else {
            Modal.success({
              title: "Password successfully reset.",
              content: "",
            });
            this.props.history.push("/admin/login");
          }
        });
      }
    });
  };

  componentDidMount() {
    if (getToken()) {
      const { history } = this.props;
      history.push("/admin");
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      isLoading,
    } = this.props;

    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        className="login-container"
      >
        <Col xs={20} sm={15} md={15} lg={10}>
          <Spin spinning={isLoading}>
            <Row
              type="flex"
              justify="start"
              align="middle"
              className="login-header"
            >
              <Col span={24}>
                <img
                  src={Logo}
                  alt="login-logo"
                  style={{ objectFit: "contain", height: "150px" }}
                />
              </Col>
              <Col span={24}>
                <h2>Reset Password</h2>
              </Col>
            </Row>

            <Form onSubmit={this.handleSubmit}>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your new password",
                    },
                  ],
                })(<Input type="password" placeholder="New password" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("confirm", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your confirm password",
                    },
                  ],
                })(<Input type="password" placeholder="Confirm password" />)}
              </Form.Item>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="login-footer"
              >
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="form-btn-submit"
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
    );
  }
}

const LoginForm = Form.create({ name: "login_form" })(Login);

export default LoginForm;
