import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import * as _ from "lodash";
import { Layout } from "antd";

import "./App.scss";
import "antd/dist/antd.css";

import SchedulerHeader from "./shared/SchedulerHeader";
// import Footer from "./shared/Footer";
import NotFound from "./NotFound";
import Search from "./Search";
import Scheduler from "./Scheduler";
import AdminHeader from "./shared/AdminHeader";
import AdminFooter from "./shared/AdminFooter";
import Login from "./Login";
import Reset from "./Reset";
import Admin from "./Appointment";
import Profile from "./Profile";

moment.updateLocale(moment.locale(), { invalidDate: "N/A" });

const genericErrors = {
  401: {
    title: "Resource not Found",
    message: "Unable to load resource",
  },
  500: {
    title: "Internal Server Error",
    message: "The server was unable to complete your request.",
  },
  502: {
    title: "Bad Gateway",
    message: "The server was unable to complete your request.",
  },
  503: {
    title: "Service Unavailable",
    message: "The server was unable to complete your request.",
  },
};

const paths = [
  {
    default: true,
    exact: true,
    slug: "search",
    route: "/",
    label: "Search",
    component: Search,
    display: [""],
  },
  {
    default: false,
    exact: true,
    slug: "admin/login",
    route: "/admin/login",
    label: "Login",
    component: Login,
    display: [""],
  },
  {
    default: false,
    exact: true,
    slug: "admin/reset/:token",
    route: "/admin/reset/:token",
    label: "Reset",
    component: Reset,
    display: [""],
  },
  {
    default: false,
    exact: true,
    slug: "admin",
    route: "/admin",
    label: "Admin",
    component: Admin,
    display: [""],
  },
  {
    default: false,
    exact: true,
    slug: "scheduler",
    route: "/schedule",
    label: "Scheduler",
    component: Scheduler,
    display: [""],
  },
  {
    default: false,
    exact: true,
    slug: "admin/profile",
    route: "/admin/profile",
    label: "Profile",
    component: Profile,
    display: [""],
  },
];

const toastXHRError = (code) => {
  const error = genericErrors[code];
  if (error) {
    console.error(error.title, error.message);
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error.response.status) {
          case 401 /* signout() */:
            break;
          case 404:
            toastXHRError(404);
            break;
          case 500:
            toastXHRError(500);
            break;
          case 502:
            toastXHRError(502);
            break;
          case 503:
            toastXHRError(503);
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );

    axios.defaults.transformResponse = axios.defaults.transformResponse
      .concat
      // (response) => func(response)
      ();
  }

  // componentDidMount () {
  //   if (isLocalStorageEmpty()) {
  //     this.setState({ isLoggedIn: false })
  //   } else {
  //     this.setState({ isLoggedIn: true })
  //   }
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
  //     this.setState({ isLoggedIn: true });
  //     // window.location.reload()
  //   }
  // }

  render() {
    return (
      <Layout className="layout">
        <Layout.Content>
          {/* {!this.props.history.location.pathname === "/" && (
            <SchedulerHeader {...this.props} />
          )} */}

          {this.props.history.location.pathname === "/admin" ||
          this.props.history.location.pathname === "/admin/profile" ? (
            <AdminHeader {...this.props} paths={paths} />
          ) : (
            ""
          )}

          <Layout className="sub-layout">
            <Layout.Content className="sub-layout-content">
              <Switch className="sub-layout__content__switch">
                {window.scrollTo(0, 0)}
                {_.map(paths, (path) => (
                  <Route
                    key={path.slug}
                    exact={path.exact}
                    path={path.route}
                    component={path.component}
                  />
                ))}
                <Route component={NotFound} />
                <Redirect to="/404" />
              </Switch>
            </Layout.Content>
          </Layout>

          {this.props.history.location.pathname === "/admin" ||
          this.props.history.location.pathname === "/admin/profile" ? (
            <AdminFooter {...this.props} paths={paths} />
          ) : (
            ""
          )}
        </Layout.Content>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.login.loggedIn,
    state: state,
  };
}

export default withRouter(connect(mapStateToProps, {})(App));

// import React, { Component } from "react";
// import { Route, Switch, Redirect, withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import axios from "axios";
// import moment from "moment";
// import * as _ from "lodash";
// import { Layout } from "antd";

// import "./App.scss";
// import "antd/dist/antd.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import Header from "./shared/Header";
// import Footer from "./shared/Footer";
// import NotFound from "./NotFound";
// // import Dashboard from "./Dashboard";
// import Scheduler from "./Scheduler";
// import Login from "./Login";
// // import Calendar from "./Appointment";
// // import Dayoffs from "./Appointment";
// import Appointment from "./Appointment";
// import Profile from "./Profile";
// // import Users from "./Users";

// moment.updateLocale(moment.locale(), { invalidDate: "N/A" });

// const genericErrors = {
//   401: {
//     title: "Resource not Found",
//     message: "Unable to load resource",
//   },
//   500: {
//     title: "Internal Server Error",
//     message: "The server was unable to complete your request.",
//   },
//   502: {
//     title: "Bad Gateway",
//     message: "The server was unable to complete your request.",
//   },
//   503: {
//     title: "Service Unavailable",
//     message: "The server was unable to complete your request.",
//   },
// };

// const paths = [
//   {
//     default: true,
//     exact: true,
//     slug: "scheduler",
//     route: "/",
//     label: "Scheduler",
//     component: Scheduler,
//     display: [""],
//   },
//   {
//     default: false,
//     exact: true,
//     slug: "admin/login",
//     route: "/admin/login",
//     label: "Login",
//     component: Login,
//     display: [""],
//   },
//   {
//     default: false,
//     exact: true,
//     slug: "admin",
//     route: "/admin",
//     label: "Dashboard",
//     component: Appointment,
//     display: [""],
//   },
//   {
//     default: false,
//     exact: false,
//     slug: "admin/appointments",
//     route: "/admin/appointments",
//     label: "Appointments",
//     component: Appointment,
//     display: ["tab"],
//     adminOnly: false,
//   },
//   {
//     default: false,
//     exact: false,
//     slug: "admin/profile",
//     route: "/admin/profile",
//     label: "Profile",
//     component: Profile,
//     display: [""],
//     adminOnly: false,
//   },
// ];

// const toastXHRError = (code) => {
//   const error = genericErrors[code];
//   if (error) {
//     console.error(error.title, error.message);
//   }
// };

// // const isLocalStorageEmpty = () => {
// //   let strResult;
// //   if (localStorage.getItem("TOKEN") !== null) {
// //     strResult = true;
// //   } else {
// //     strResult = true;
// //   }
// //   return strResult;
// // };

// class App extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoggedIn: false,
//     };

//     axios.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         switch (error.response.status) {
//           case 401 /* signout() */:
//             break;
//           case 404:
//             toastXHRError(404);
//             break;
//           case 500:
//             toastXHRError(500);
//             break;
//           case 502:
//             toastXHRError(502);
//             break;
//           case 503:
//             toastXHRError(503);
//             break;
//           default:
//             break;
//         }
//         return Promise.reject(error);
//       }
//     );

//     axios.defaults.transformResponse = axios.defaults.transformResponse
//       .concat
//       // (response) => func(response)
//       ();
//   }

//   // componentDidMount () {
//   //   if (isLocalStorageEmpty()) {
//   //     this.setState({ isLoggedIn: false })
//   //   } else {
//   //     this.setState({ isLoggedIn: true })
//   //   }
//   // }

//   componentWillReceiveProps(nextProps) {
//     if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
//       this.setState({ isLoggedIn: true });
//       // window.location.reload()
//     }
//   }

//   render() {
//     return (
//       <Layout className="layout">
//         <Layout.Content>
//           {this.props.history.location.pathname === "/admin/appointments" ||
//           this.props.history.location.pathname === "/admin/profile" ? (
//             <Header {...this.props} paths={paths} />
//           ) : (
//             ""
//           )}

//           <Layout className="sub-layout">
//             <Layout.Content className="sub-layout__content">
//               <Switch className="sub-layout__content__switch">
//                 {window.scrollTo(0, 0)}
//                 {_.map(paths, (path) => (
//                   <Route
//                     key={path.slug}
//                     exact={path.exact}
//                     path={path.route}
//                     component={path.component}
//                   />
//                 ))}
//                 <Route component={NotFound} />
//                 <Redirect to="/404" />
//               </Switch>
//             </Layout.Content>
//           </Layout>
//           {this.props.history.location.pathname === "/admin/appointments" ||
//           this.props.history.location.pathname === "/admin/profile" ? (
//             <Footer />
//           ) : (
//             ""
//           )}
//         </Layout.Content>
//       </Layout>
//     );

//     // return this.state.isLoggedIn ? (
//     //   <Login />
//     // ) : (
//     //   <Layout className="layout">
//     //     {/* <Header {...this.props} paths={paths} /> */}
//     //     <Layout.Content>
//     //       <Layout className="sub-layout">
//     //         {/* <Sider {...this.props} paths={paths} /> */}
//     //         <Layout.Content className="sub-layout__content">
//     //           <Switch className="sub-layout__content__switch">
//     //             {/* // this will scroll to top when switch changes. */}
//     //             {window.scrollTo(0, 0)}
//     //             {_.map(paths, (path) => (
//     //               <Route
//     //                 key={path.slug}
//     //                 exact={path.exact}
//     //                 path={path.route}
//     //                 component={path.component}
//     //               />
//     //             ))}
//     //             <Route component={NotFound} />
//     //             <Redirect to="/404" />
//     //           </Switch>
//     //         </Layout.Content>
//     //       </Layout>
//     //     </Layout.Content>
//     //     {/* <Footer {...this.props} paths={paths} /> */}
//     //   </Layout>
//     // );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     loggedIn: state.login.loggedIn,
//     state: state,
//   };
// }

// export default withRouter(connect(mapStateToProps, {})(App));
